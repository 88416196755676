import {
  gsap,
  CSSPlugin,
  Power0,
  Power1,
  Power2,
  Power3,
  Power4,
  Linear,
  Quad,
  Cubic,
  Quart,
  Quint,
  Strong,
  Elastic,
  Back,
  SteppedEase,
  Bounce,
  Sine,
  Expo,
  Circ,
} from "gsap/all";
import CountUp from "countup";
import $ from "jquery";

const gsapPlugins = [CSSPlugin];

const easings = {
  Power0,
  Power1,
  Power2,
  Power3,
  Power4,
  Linear,
  Quad,
  Cubic,
  Quart,
  Quint,
  Strong,
  Elastic,
  Back,
  SteppedEase,
  Bounce,
  Sine,
  Expo,
  Circ,
};

class ScrollInView {
  constructor(elements) {
    this.initScrollInView(elements);
  }

  initScrollInView(elements) {
    this.windowHeight = window.innerHeight;
    this.minVisibleHeight = 30;
    this.animHasStartedClass = "anim-started";
    this.groupAttr = "data-scroll-in-view-group";
    this.delayAttr = "data-scroll-in-view-delay";
    this.scrollingElements = Array.from(elements);

    if (this.scrollingElements.length) {
      this.scrollInViewElementsArray = [];
      this.remainingElementsToAnimCount = this.scrollingElements.length;

      this.scrollingElements.forEach((el, i) => {
        let attr = {
          delay: 0,
          duration: 1,
          ease: "Expo.easeOut",
          group: null,
          trigger: false,
        };
        if (el.hasAttribute("data-scroll")) {
          try {
            attr = JSON.parse(el.getAttribute("data-scroll"));
          } catch (e) {
            console.error("Error while parsing json: ", attr, e, el);
          }
        }

        this.scrollInViewElementsArray[i] = {
          index: i,
          el: el,
          $el: jQuery(el),
          height: el.getBoundingClientRect().height,
          top: el.getBoundingClientRect().top + window.scrollY,
          delay: !isNaN(attr.delay) ? parseFloat(attr.delay) : 0,
          duration: !isNaN(attr.duration) ? parseFloat(attr.duration) : 1,
          ease: attr.ease || "Expo.easeOut",
          group: !isNaN(attr.group) ? parseInt(attr.group) : null,
          trigger: attr.trigger || false,
          hasStarted: false,
        };
      });
    }

    this.resize();
    this.bindEvents();
    this.scroll();
  }

  bindEvents() {
    this.scrollFn = this.scroll.bind(this);
    this.resizeFn = this.resize.bind(this);
    window.addEventListener("scroll", this.scrollFn);
    window.addEventListener("resize", this.resizeFn);
    window.addEventListener("load", this.resizeFn);
  }

  unbindEvents() {
    window.removeEventListener("scroll", this.scrollFn);
    window.removeEventListener("resize", this.resizeFn);
    window.removeEventListener("load", this.resizeFn);
    this.scrollFn = null;
    this.resizeFn = null;
  }

  scroll() {
    if (this.remainingElementsToAnimCount !== 0) {
      this.scrollInViewElementsArray.forEach((element) => {
        if (element && this.canStartScrollInViewAnimation(element)) {
          if (!element.hasStarted) {
            element.hasStarted = true;
            this.animateScrollInViewElement(element);

            if (element.group) {
              this.scrollInViewElementsArray.forEach((groupEl) => {
                if (groupEl && groupEl.group === element.group) {
                  groupEl.hasStarted = true;
                  this.animateScrollInViewElement(groupEl);
                }
              });
            }
          }
        }
      });
    }
  }

  isInScreen(element) {
    const scrollOffset = window.scrollY;
    return (
      (scrollOffset > element.top &&
        scrollOffset <= element.top + element.height) ||
      this.windowHeight + scrollOffset > element.top
    );
  }

  canStartScrollInViewAnimation(element) {
    return (
      this.isInScreen(element) &&
      this.windowHeight + window.scrollY - element.top >= this.minVisibleHeight
    );
  }

  animateScrollInViewElement(element) {
    const isInverted = element.$el.hasClass("inv");
    const isCountUp = element.$el.hasClass("countup");
    const opacity = 1;

    gsap.to(element.$el, {
      duration: element.duration,
      x: 0,
      y: 0,
      rotation: 0,
      rotationX: 0,
      rotationY: 0,
      scaleX: 1,
      scaleY: 1,
      opacity: opacity,
      ease: this.getEaseFunction(element.ease),
      force3D: true,
      delay: element.delay,
      onComplete: () => {
        if (element.trigger) {
          jQuery(window).trigger(element.trigger, element);
        }
        element.$el.addClass("scroll-in-view-anim-ended");
        this.removeElementFromScrollInViewElementsArray(element);
      },
    });

    if (isCountUp) {
      const options = {
        duration: 3,
        decimals: 0,
        separator: "",
        useGrouping: false,
        startVal: 0,
        endVal: parseInt(element.$el.text(), 10),
      };
      const counter = new CountUp(
        element.$el[0],
        options.startVal,
        options.endVal,
        options.decimals,
        options.duration,
        options
      );

      if (!counter.error) {
        counter.start();
      } else {
        console.log(counter.error);
      }
    }
  }

  removeElementFromScrollInViewElementsArray(element) {
    if (this.scrollInViewElementsArray[element.index]) {
      this.scrollInViewElementsArray[element.index] = null;
      this.remainingElementsToAnimCount--;
    }
  }

  getEaseFunction(ease) {
    const [easeFamily, easeType] = ease.split(".");
    return easings[easeFamily][easeType];
  }

  resize() {
    this.windowHeight = window.innerHeight;
  }

  destroy() {
    this.unbindEvents();
    this.scrollingElements.length = 0;
    this.scrollInViewElementsArray.length = 0;
  }

  reload(newElements) {
    this.destroy();
    this.initScrollInView(newElements);
  }
}

export default ScrollInView;
