import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";
import "@fontsource/poppins/100-italic.css";
import "@fontsource/poppins/200-italic.css";
import "@fontsource/poppins/300-italic.css";
import "@fontsource/poppins/400-italic.css";
import "@fontsource/poppins/500-italic.css";
import "@fontsource/poppins/600-italic.css";
import "@fontsource/poppins/700-italic.css";
import "@fontsource/poppins/800-italic.css";
import "@fontsource/poppins/900-italic.css";
import "@fontsource/indie-flower";

import imagesLoaded from "imagesloaded";

// import SiteHeader from "./components/site-header";
import ScrollInView from "./components/scroll-in-view";

$(function () {
  //! Le header est déjà par défaut en sticky. Garder donc cela en commentaire.
  // const $headerNav = $(".header-nav");

  // if ($headerNav) {
  //   new SiteHeader($headerNav, 40);
  // }

  const $scrollInViewElements = $(".scroll-in-view");
  if ($scrollInViewElements.length) {
    $scrollInViewElements.imagesLoaded(function () {
      new ScrollInView($scrollInViewElements);
    });
  }
});
